<script lang="ts">
	import { override } from '$lib/utils/classnames'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import { twMerge } from 'tailwind-merge'

	interface Props {
		class?: string
		isDisabled?: boolean
		children?: import('svelte').Snippet
		onclick?: () => void
	}

	let { class: className = '', isDisabled = false, children, onclick }: Props = $props()

	function handleInteraction(event: Event) {
		event.preventDefault()
		event.stopPropagation()

		onclick?.()
	}

	let computedClassName = $derived(
		twMerge(
			override(
				'dark:bg-brand-gray-4/10 border-brand-gray-4 dark:hover:bg-brand-gray-4/20 border dark:text-white text-black transition-all px-3 py-1 text-xs rounded-md flex items-center touch-manipulation	',
				className,
			),
		),
	)
</script>

<button
	disabled={isDisabled}
	class={computedClassName}
	onclick={stopPropagation(handleInteraction)}
>
	{@render children?.()}
</button>
